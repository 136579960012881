<template>
  <div class="flightsDashboard js-tabBox flightsDashboard--admin">
    <div class="flightsDashboard__header">
      <div class="flightsDashboard__title">Flights requests by date and time</div>
      <div class="flightsDashboard__nav">
        <div class="flightsDashboard__nav-item js-tabNavItem" @click="tabActivate">
          <span class="text">Current</span>
        </div>
        <div class="flightsDashboard__nav-item js-tabNavItem" @click="tabActivate">
          <span class="text">Historical</span>
        </div>
        <div class="flightsDashboard__nav-item js-tabNavItem" @click="tabActivate">
          <span class="text">Upcoming</span>
        </div>
      </div>
    </div>
    <div class="flightsDashboard__content">
      <div class="flightsDashboard__content-inner">
        <div class="flightsDashboard__content-item js-tabContentItem">
          <FlightsDashboardTable :list="flightsList"/>
        </div>
        <div class="flightsDashboard__content-item js-tabContentItem">
          <FlightsDashboardTable :list="flightsList"/>
        </div>
        <div class="flightsDashboard__content-item js-tabContentItem">
          <FlightsDashboardTable :list="flightsList"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import FlightsDashboardTable from '@/components/dashboard/FlightsDashboardTable/FlightsDashboardTable'
  import { Tabs } from '@/js/plugins/tabs'
  import '@/views/dashboard/flyer/Flights/Flights.styl'

  export default {
    name: 'FlightsAdmin',
    data: () => ({
      tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
    }),
    computed: {
      flightsList () {
        return this.$store.state.adminRequests.list
      },
    },
    mounted () {
      this.tabs.addActiveForMouted()
    },
    methods: {
      tabActivate (event) {
        this.tabs.addActiveForClick(event.currentTarget)
      },
    },
    components: {
      FlightsDashboardTable,
    },
  }
</script>
