<template>
  <div class="flightsDashboard__table">
    <div class="flightsDashboard__table-header">
      <div class="flightsDashboard__table-cell">№</div>
      <div class="flightsDashboard__table-cell">Name</div>
      <div class="flightsDashboard__table-cell">Email</div>
      <div class="flightsDashboard__table-cell">Date/time</div>
      <div class="flightsDashboard__table-cell">From</div>
      <div class="flightsDashboard__table-cell">To</div>
      <div class="flightsDashboard__table-cell">Nautical miles</div>
    </div>
    <div class="flightsDashboard__table-body js-customScroll">
      <vue-scroll :ops="ops">
        <div
          v-for="item of list"
          :key="item.id"
          class="flightsDashboard__table-row"
        >
          <div class="flightsDashboard__table-cell">
            <div class="flightsDashboard__table-index">{{item.id}}</div>
          </div>
          <div class="flightsDashboard__table-cell flightsDashboard__table-cell--name">
            <router-link class="flightsDashboard__table-name" to="/dashboard/profile">{{item.name}}</router-link>
          </div>
          <div class="flightsDashboard__table-cell">{{item.email}}</div>
          <div class="flightsDashboard__table-cell flightsDashboard__table-cell--date">{{item.dateTime}}</div>
          <div class="flightsDashboard__table-cell flightsDashboard__table-cell--from">
            <span class="flightsDashboard__table-title">{{item.takeOff}}</span>
            <span class="arrow"><SvgIcon name="right-arrow-2"/></span>
          </div>
          <div class="flightsDashboard__table-cell flightsDashboard__table-cell--to">
            <span class="flightsDashboard__table-title">{{item.land}}</span>
          </div>
          <div class="flightsDashboard__table-cell">
            <span class="flightsDashboard__table-caption forMobile">Nautical miles:</span>
            <span>{{item.miles}} NM</span>
            <router-link class="flightsDashboard__table-detail" to="">details</router-link>
          </div>
        </div>
      </vue-scroll>
    </div>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'

  export default {
    props: ['list'],
    name: 'FlightsDashboardTable',
    data: () => ({
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    components: {
      SvgIcon,
    },
  }
</script>
